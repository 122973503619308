@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

.appHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 20px 0 84px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .appHeaderBackground {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(21, 21, 21, 0.7) 0%,
      rgba(21, 21, 21, 0) 100%
    );
    opacity: 1;
    filter: blur(7px);
    backdrop-filter: blur(7px);
    mask: linear-gradient(
      rgba(21, 21, 21, 1) 0%,
      rgba(21, 21, 21, 1) 50%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  .appHeaderContainer {
    width: 100%;
    max-width: 1560px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;
    z-index: 1;

    .appHeaderContent {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .statecsLogo {
        width: 161px;
        display: block;
      }

      .statecsLogoMobileContact {
        width: 161px;
        display: none;
      }

      .appHeaderItems {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .siteSearch360 {
          width: 100%;
          max-width: 250px;
          min-width: 200px;
          margin-left: 16px;
        }

        .searchBarScript {
          position: relative;
          width: 400px;
          border: 1px solid black;
        }

        .headerItem {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          margin-left: 57px;
          cursor: pointer;
        }

        .appHeaderItems > .headerItem:nth-last-child(1) {
          margin-left: 0;
        }
      }

      .mobileMenuIcon {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .appHeader {
    .appHeaderContainer {
      .appHeaderContent {
        .appHeaderItems {
          .headerItem {
            margin-left: 40px;
          }

          .headerItemDark {
            margin-left: 40px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .appHeader {
    .appHeaderContainer {
      .appHeaderContent {
        .statecsLogo {
          width: 140px;
          display: block;
        }

        .appHeaderItems {
          .headerItem {
            margin-left: 30px;
          }

          .headerItemDark {
            margin-left: 30px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1265px) {
  .appHeader {
    .appHeaderContainer {
      .appHeaderContent {
        .statecsLogo {
          width: 120px;
          display: block;
        }

        .appHeaderItems {
          .headerItem {
            margin-left: 25px;
            font-size: 14px;
          }

          .headerItemDark {
            margin-left: 25px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1160px) {
  .appHeader {
    .appHeaderContainer {
      .appHeaderContent {
        .statecsLogo {
          width: 110px;
          display: block;
        }

        .appHeaderItems {
          .headerItem {
            margin-left: 14px;
            font-size: 14px;
          }

          .headerItemDark {
            margin-left: 16px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .appHeader {
    padding: 15px 0;
    .appHeaderContainer {
      .appHeaderContent {
        .statecsLogo {
          display: none;
        }

        .statecsLogoMobileContact {
          display: block;
        }

        .appHeaderItems {
          display: none;
        }

        .mobileMenuIcon {
          display: block;
        }
      }
    }
  }
}
