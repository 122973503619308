@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.servicesWhyStatecs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 60px 0;
  background: #2730ae;
  margin-bottom: 60px;

  .servicesWhyStatecsTitle {
    width: 100%;
    max-width: 582px;
    padding: 0 20px;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 900;
    font-size: 68.8303px;
    line-height: 103px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }

  .servicesWhyStatecsText {
    width: 100%;
    max-width: 940px;
    padding: 0 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
  }

  .servicesWhyStatecsSubtitle {
    width: 100%;
    max-width: 608px;
    padding: 0 20px;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    margin: 20px 0 40px 0;
  }

  .servicesWhyStatecsCards {
    width: 100%;
    max-width: 1045px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1024px) {
  .servicesWhyStatecs {
    padding: 20px 0 20px 0;
    background: #2730ae;
    margin-bottom: 60px;

    .servicesWhyStatecsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      margin-bottom: 8px;
      text-align: center;
    }

    .servicesWhyStatecsText {
      width: 100%;
      max-width: 940px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      text-align: center;
      color: #ffffff;
      margin-bottom: 30px;
    }

    .servicesWhyStatecsSubtitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin: 0 20px 0 0;
    }

    .servicesWhyStatecsCards {
      width: 100%;
      max-width: 1045px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
    }
  }
}
