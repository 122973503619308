@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.TermAndConditionsPage {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dee4f9;
  padding: 0 20px;

  .TermAndConditionsContainer {
    max-width: 1560px;
    padding: 0 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: white;
    padding-top: 155px;
    margin-bottom: 40px;
    padding-bottom: 40px;

    .TermAndConditionsSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: start;
      color: #151515;
      margin-bottom: 30px;
    }

    .TermAndConditionsSectionTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-align: start;
      margin-bottom: 30px;
      color: #151515;
      padding-left: 30px;
    }

    .TermAndConditionsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      text-align: start;
      margin-bottom: 30px;
      color: #151515;
    }

    .TermAndConditionsText {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: start;
      color: #151515;
      margin-bottom: 4px;
    }

    ul {
      li {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: start;
        color: #151515;
        margin-bottom: 5px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .TermAndConditionsPage {
    padding: 0 20px;

    .TermAndConditionsContainer {
      padding: 0 40px;
      padding-top: 100px;

      .TermAndConditionsSubtitle {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
      }

      .TermAndConditionsSectionTitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
        padding-left: 15px;
      }

      .TermAndConditionsTitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 15px;
      }

      .TermAndConditionsText {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: start;
        color: #151515;
        margin-bottom: 4px;
      }

      ul {
        li {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 15px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
