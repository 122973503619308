.statecsAdvantageSubcard {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #2730ae;
  margin-right: 10px;
  transition: 2000ms;

  .statecsAdvantageIcon {
    margin-right: 50px;
  }

  .statecsAdvantageSubcardContent {
    max-width: 168px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    text-align: start;
  }
}

.statecsAdvantageSubcard:hover {
  box-shadow: inset 0 200px 0 0 #151515;
  color: white;
}

@media screen and (max-width: 1300px) {
  .statecsAdvantageSubcard {
    .statecsAdvantageSubcardContent {
      max-width: 168px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 36px;
      color: #ffffff;
      text-align: start;
    }
  }
}
