@font-face {
  font-family: "Poppins";
  src: url(../../../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../../../assets/fonts/Poppins-Bold.ttf);
}

.cdisc-compliant-mapping {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 56px 240px 56px 114px;

  .text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 40px;
    text-align: start;
  }

  .title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: start;
  }

  .list-title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: start;
    margin-bottom: 40px;
  }

  .list {
    padding-left: 30px;
    margin-bottom: 40px;
  }

  .subtext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: start;
  }
}
