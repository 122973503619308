@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.blogSinglePageContent {
  width: 100%;
  max-width: 920px;
  padding: 0 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  .title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 54px;
    color: #2a2b2d;
    margin-bottom: 9px;
    text-align: start;
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #2a2b2d;
    text-align: start;
  }

  .dateContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .date {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #666a6d;
    }

    .social {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .statecsLinkedinSocialIcon {
        margin-right: 20px;
      }
    }
  }

  .blogContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    color: #2a2b2d;
    text-align: start;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .statecsBlogImageBig {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .blogSinglePageContent {
    max-width: 100%;
    padding: 0 20px;
    margin-top: 20px;

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 30px;
      line-height: 45px;
      color: #2a2b2d;
      margin-bottom: 10px;
      text-align: start;
    }

    .blogContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 27px;
      color: #2a2b2d;
      text-align: start;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .statecsBlogImageBig {
      width: 100%;
    }
  }
}
