@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.ourMission {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 60px;

  .ourMissionHeader {
    width: 100%;
    max-width: 940px;
    padding: 0 20px;
    margin: 60px 0;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #151515;
    text-align: center;
  }

  .ourMissionContainer {
    width: 100%;
    max-width: 1560px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ourMissionTextContent {
      width: 100%;
      max-width: 710px;
      margin-right: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .ourMissionTextContentTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 94%;
        color: #151515;
        padding-top: 167px;
        margin-bottom: 30px;
        text-align: start;
      }

      .ourMissionTextContentSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #151515;
        text-align: start;
      }
    }

    .ourMissionCover {
      width: 100%;
      max-width: 750px;
      height: auto;
    }
  }
}

@media screen and (max-width: 1024px) {
  .ourMission {
    width: 100%;
    margin-bottom: 40px;

    .ourMissionHeader {
      width: 100%;
      max-width: 940px;
      padding: 31px 20px 40px 20px;
      margin: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #151515;
    }

    .ourMissionContainer {
      max-width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;

      .ourMissionTextContent {
        max-width: 100%;
        margin-right: 0;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ourMissionTextContentTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #151515;
          padding-top: 16px;
          margin-bottom: 8px;
          text-align: center;
        }

        .ourMissionTextContentSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #151515;
        }
      }

      .ourMissionCover {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }
}
