@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.regulatoryAffairs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f9f9f9;

  .regulatoryAffairsHeader {
    width: 100%;
    height: 887px;
    display: flex;
    background-image: url(../../assets/images/regulatory-affairs.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .regulatoryAffairsHeaderContainer {
      width: 100%;
      max-width: 1240px;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .regulatoryAffairsHeaderTitle {
        max-width: 503px;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 120%;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 30px;
        text-align: start;
      }

      .regulatoryAffairsHeaderSubtitle {
        max-width: 514px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: #ffffff;
        text-align: start;
      }
    }
  }

  .servicesOurAdvantages {
    width: 100%;
    max-width: 1249px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 50px;

    .servicesOurAdvantagesTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      color: #2431d8;
      margin-bottom: 7px;
    }

    ul {
      li {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        text-align: start;
        color: #151515;
        margin-bottom: 10px;
      }

      .listSubItem {
        width: 100%;
        display: block;
        margin-bottom: 10px;
        text-align: start;
      }
    }
  }

  .whyStatecsMedicalWriting {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .servicesWhyStatecsSubcards {
    width: 100%;
    max-width: 1249px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 90px;
  }

  .servicesWhyStatecsSubcards > .statecsAdvantageSubcard:nth-last-child(1) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .regulatoryAffairs {
    width: 100%;

    .regulatoryAffairsHeader {
      height: 475px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .regulatoryAffairsHeaderContainer {
        max-width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .regulatoryAffairsHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          margin-bottom: 20px;
        }

        .regulatoryAffairsHeaderSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 28px;
        }
      }
    }

    .servicesOurAdvantages {
      max-width: 100%;
      margin-bottom: 20px;

      .servicesOurAdvantagesTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #2431d8;
        margin-bottom: 7px;
      }

      ul {
        li {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #151515;
          margin-bottom: 10px;
        }
      }
    }

    .servicesWhyStatecsSubcards {
      max-width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 60px;
    }

    .servicesWhyStatecsSubcards > .statecsAdvantageSubcard {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
