@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.statisticsSectionCard {
  width: 100%;
  max-width: 342px;
  height: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 52px 50px 52px;
  border-right: 1px solid rgba(0, 0, 0, 0.25);

  .inNumbers {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 96px;
    color: #000000;
    margin-bottom: 25px;
    text-align: start;
  }

  .title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    text-align: start;
    margin-bottom: 10px;
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: start;
    color: #000000;
  }
}

@media screen and (max-width: 1110px) {
  .statisticsSectionCard {
    padding: 20px 30px 50px 30px;
    .inNumbers {
      line-height: 60px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1110px) {
  .statisticsSectionCard {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    .inNumbers {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 64px;
      line-height: 96px;
      color: #000000;
      margin-bottom: 25px;
      text-align: start;
    }

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #000000;
      text-align: center;
      margin-bottom: 10px;
    }

    .subtitle {
      max-width: 500px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #000000;
    }
  }
}
