@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.ourValues {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 93px;

  .ourValuesConatiner {
    width: 100%;
    max-width: 1560px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .ourValuesTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 94%;
      color: #151515;
      margin-bottom: 30px;
    }

    .ourValuesCards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

@media screen and (max-width: 1024px) {
  .ourValues {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;

    .ourValuesConatiner {
      max-width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .ourValuesTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 94%;
        color: #151515;
        text-align: center;
        margin-bottom: 20px;
      }

      .ourValuesCards {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
