.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 76px;

  .contactUsForm {
    width: 100%;

    .ant-form-item {
      margin-bottom: 21px;
    }

    .formItemTextArea {
      .ant-input {
        border: 1px solid #151515;
        border-radius: 20px;
      }
    }

    .ant-input {
      border: 1px solid #151515;
      border-radius: 100px;
    }

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
      background: transparent;
      border-color: #ff4d4f;
    }

    .ant-col-16 {
      max-width: 100%;

      input {
        height: 60px;
        background-color: #dee4f9;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #151515;
      }

      input::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #151515;
      }

      textarea {
        height: 169px;
        background-color: #dee4f9;
        resize: none;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #151515;
      }

      textarea::placeholder {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #151515;
      }
    }

    .submitButtonContainer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .submitButton {
        height: 66px;
        background: #2730ae;
        border: 2px solid #2730ae;
        border-radius: 100px;
        padding: 15px 100px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        transition: 300ms;
      }

      .submitButton:hover {
        background: #5985ef;
        border: 2px solid #ffffff;
        border-radius: 100px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 20px 46px 20px;
    background: #dee4f9;

    .contactUsForm {
      .submitButtonContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .submitButton {
          height: 66px;
          background: #2730ae;
          border-radius: 100px;
          padding: 15px 100px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
        }
      }
    }
  }
}
