@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}


.statecsAdvantage {
  width: 100%;
  height: 740px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 76px;
  background: #f6f8ff;

  .statecsAdvantageBackground {
    width: 100%;
    height: 708px;
    background-image: url(../../../../assets/images/services-background-second.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 0;

    .statecsAdvantageBackgroundTitle {
      display: block;
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      line-height: 54px;
      text-transform: uppercase;
      color: #ffffff;
      text-align: center;
      margin-top: 334px;
    }
  }

  .statecsAdvantageTitle {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 54px;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 32px;
  }

  .statecsAdvantageCards {
    width: 100%;
    max-width: 985px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

@media screen and (max-width: 1024px) {
  .statecsAdvantage {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 60px;

    .statecsAdvantageBackground {
      width: 100%;
      height: 375px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      .statecsAdvantageBackgroundTitle {
        display: inline;
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 20px;
      }
    }

    .statecsAdvantageTitle {
      display: none;
    }

    .statecsAdvantageCards {
      max-width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}
