.blogPageContent {
  width: 100%;
  max-width: 1560px;
  padding: 0 20px;
  margin-top: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .blogPageContentCards {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;
    padding: 0 20px;
  }
  .blogPageContentCards > .blogPageItemCard:nth-child(4) {
    margin-right: 0;
  }

  .blogPageContentCards > .blogPageItemCard:nth-child(8) {
    margin-right: 0;
  }

  .ant-pagination {
    display: flex;
    flex-direction: row;

    .ant-pagination-prev {
      background-color: white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    li {
      background-color: white;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        background-color: white;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.blogPageContent .blogPageContentCards:nth-child(2) {
  margin-bottom: 79px;
}

@media screen and (max-width: 1024px) {
  .blogPageContent {
    max-width: 100%;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .blogPageContentCards {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 35px;
      padding: 0 20px;
    }
    .blogPageContentCards > .blogPageItemCard {
      margin-right: 0;
    }

    .ant-pagination {
      display: flex;
      flex-direction: row;

      .ant-pagination-prev {
        background-color: white;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li {
        background-color: white;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          background-color: white;
          border-radius: 50%;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .blogPageContent .blogPageContentCards:nth-child(2) {
    display: none;
  }
}
