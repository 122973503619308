@font-face {
  font-family: "Poppins";
  src: url(../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../assets/fonts/Poppins-Bold.ttf);
}

.newsletterSubscription {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #304bb8;

  .newsletterSubscriptionContainer {
    width: 100%;
    max-width: 1280px;
    padding: 60px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .textContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-right: 40px;

      .title {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        color: #ffffff;
        margin-bottom: 5px;
      }

      .subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
    }

    .input {
      width: 100%;
      max-width: 791px;

      .ant-input {
        border-radius: 100px;
      }

      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
      .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
        background: transparent;
        border-color: #ff4d4f;
      }

      .suffixContainer {
        width: 33px;
        height: 33px;
        margin-right: 5px;
        background-color: transparent;
        border: none;
        padding: 0;
        cursor: pointer;

        .suffix {
          width: 33px;
          height: auto;
        }
      }

      .ant-input-affix-wrapper {
        border: 1px solid #ffffff;
        border-radius: 100px;
        background-color: transparent;
      }

      .contactUsForm {
        .ant-form-item {
          margin-bottom: 0;

          .ant-col-16 {
            max-width: 100%;

            input {
              height: 60px;
              background-color: transparent;
            }

            input::placeholder {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: rgba(183, 204, 255, 0.41);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .newsletterSubscription {
    .newsletterSubscriptionContainer {
      width: 100%;
      max-width: 1280px;
      padding: 26px 20px 9px 20px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .textContent {
        margin-right: 0;

        .title {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
          margin-bottom: 5px;
        }

        .subtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: start;
          color: #ffffff;
          margin-bottom: 22px;
        }
      }
    }
  }
}
