@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.trainingApplyForm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 90px 0;
  background: #f9f9f9;

  .trainingApplyFormContainer {
    width: 100%;
    max-width: 860px;
    padding: 0 20px;

    .joinUsModal {
      width: 100%;

      .formHeader {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .formTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 85.2427px;
        line-height: 128px;
        color: #151515;
        margin-bottom: 10px;
      }

      .formSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #151515;
        margin-bottom: 40px;
      }

      .ant-form-item {
        margin-bottom: 21px;
      }

      .formItemTextArea {
        .ant-input {
          border: 1px solid #151515;
          border-radius: 20px;
        }
      }

      .ant-input {
        border: 1px solid #151515;
        border-radius: 100px;
      }

      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
      .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: transparent;
        border-color: #ff4d4f;
      }

      .ant-col-16 {
        max-width: 100%;

        input {
          height: 60px;
          background-color: transparent;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
        }

        input::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
        }

        textarea {
          height: 285px;
          background-color: transparent;
          resize: none;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
        }

        textarea::placeholder {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          color: #151515;
        }

        .uploader {
          border: 1px solid #ccc;
          display: inline-block;
          padding: 6px 12px;
          cursor: pointer;
        }

        .ant-upload.ant-upload-drag {
          position: relative;
          width: 100%;
          height: 144px;
          text-align: center;
          background: #ebedf7;
          border: 1px solid #151515;
          border-radius: 20px;
          cursor: pointer;
          transition: border-color 0.3s;

          .ant-upload {
            padding: 15px 20px;

            .ant-upload-drag-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;

              .ant-upload-text {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                color: #151515;
              }

              .ant-upload-hint {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #7b82a0;
                margin-top: 21px;
              }
            }
          }
        }

        .ant-checkbox-wrapper {
          width: 100%;

          .ant-checkbox {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #151515;
          }
        }
      }

      .submitButtonContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 75px;

        .submitButton {
          height: 66px;
          background: #2730ae;
          border: 2px solid #2730ae;
          border-radius: 100px;
          padding: 15px 100px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 300;
          font-size: 24px;
          line-height: 36px;
          color: #ffffff;
          transition: 300ms;
        }

        .submitButton:hover {
          background: #5985ef;
          border: 2px solid #ffffff;
          border-radius: 100px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .trainingApplyForm {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0 90px 0;
    background: #f9f9f9;

    .trainingApplyFormContainer {
      width: 100%;
      max-width: 860px;
      padding: 0 20px;

      .joinUsModal {
        width: 100%;

        .formHeader {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }

        .formTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 48px;
          color: #151515;
          margin-bottom: 16px;
        }

        .formSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #151515;
          margin-bottom: 16px;
        }

        .ant-form-item {
          margin-bottom: 21px;
        }

        .formItemTextArea {
          .ant-input {
            border: 1px solid #151515;
            border-radius: 20px;
          }
        }

        .ant-input {
          border: 1px solid #151515;
          border-radius: 100px;
        }

        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
        .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
          background: transparent;
          border-color: #ff4d4f;
        }

        .ant-col-16 {
          max-width: 100%;

          input {
            height: 60px;
            background-color: transparent;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #151515;
          }

          input::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #151515;
          }

          textarea {
            height: 285px;
            background-color: transparent;
            resize: none;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #151515;
          }

          textarea::placeholder {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #151515;
          }

          .ant-upload.ant-upload-drag {
            position: relative;
            width: 100%;
            height: 144px;
            text-align: center;
            background: #ebedf7;
            border: 1px solid #151515;
            border-radius: 20px;
            cursor: pointer;
            transition: border-color 0.3s;

            .ant-upload {
              padding: 15px 20px;

              .ant-upload-drag-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .ant-upload-text {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 300;
                  font-size: 20px;
                  line-height: 30px;
                  color: #151515;
                }

                .ant-upload-hint {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #7b82a0;
                  margin-top: 21px;
                }
              }
            }
          }

          .ant-checkbox-wrapper {
            width: 100%;

            .ant-checkbox {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #151515;
            }
          }
        }

        .submitButtonContainer {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 75px;

          .submitButton {
            height: 66px;
            background: #2730ae;
            border-radius: 100px;
            padding: 15px 100px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 36px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
