@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}


.ant-modal-wrap {
  .joinUsModal {
    width: 990px !important;
    .ant-modal-content {
      border-radius: 25px;

      .ant-modal-body {
        background: #ffffff;
        border-radius: 25px;
        padding: 60px 85px;

        .joinUsFormContainer {
          .contactUsForm {
            width: 100%;

            .formHeader {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            }

            .formTitle {
              font-family: "PoppinsBold";
              font-style: normal;
              font-weight: 700;
              font-size: 85.2427px;
              line-height: 128px;
              color: #151515;
              margin-bottom: 10px;
              text-align: start;
            }

            .formSubtitle {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 36px;
              color: #151515;
              margin-bottom: 40px;
              text-align: start;
            }

            .ant-form-item {
              margin-bottom: 21px;
            }

            .formItemTextArea {
              .ant-input {
                border: 1px solid #151515;
                border-radius: 20px;
              }
            }

            .ant-input {
              border: 1px solid #151515;
              border-radius: 100px;
            }

            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
              background: transparent;
              border-color: #ff4d4f;
            }

            .ant-col-16 {
              width: 100%;
              max-width: 100%;

              input {
                height: 60px;
                background-color: #ffffff;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                color: #151515;
              }

              input::placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                color: #151515;
              }

              textarea {
                height: 169px;
                background-color: #ffffff;
                resize: none;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                color: #151515;
              }

              textarea::placeholder {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                color: #151515;
              }

              .ant-upload.ant-upload-drag {
                position: relative;
                width: 100%;
                height: 144px;
                text-align: center;
                background: #ebedf7;
                border: 1px solid #151515;
                border-radius: 20px;
                cursor: pointer;
                transition: border-color 0.3s;

                .ant-upload {
                  padding: 15px 20px;

                  .ant-upload-drag-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .ant-upload-text {
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 300;
                      font-size: 20px;
                      line-height: 30px;
                      color: #151515;
                    }

                    .ant-upload-hint {
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-family: "Poppins";
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      color: #7b82a0;
                      margin-top: 21px;
                    }
                  }
                }
              }

              .ant-checkbox-wrapper {
                width: 100%;

                .ant-checkbox {
                  font-family: "Poppins";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #151515;
                }
              }
            }

            .submitButtonContainer {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: center;

              .submitButton {
                height: 66px;
                background: #2730ae;
                border: 2px solid #2730ae;
                border-radius: 100px;
                padding: 15px 100px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 300;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
                transition: 300ms;
              }

              .submitButton:hover {
                background: #5985ef;
                border: 2px solid #ffffff;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ant-modal-wrap {
    .joinUsModal {
      .ant-modal-content {
        border-radius: 25px;

        .ant-modal-body {
          background: #ffffff;
          border-radius: 25px;
          padding: 16px;

          .joinUsFormContainer {
            .contactUsForm {
              width: 100%;

              .formHeader {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
              }

              .formTitle {
                font-family: "PoppinsBold";
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 48px;
                color: #151515;
              }

              .formSubtitle {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #151515;
              }
            }
          }

          .submitButtonContainer {
            .submitButton {
              width: 100%;
              height: 66px;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}
