@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.homePageSlogan {
  width: 100%;
  max-width: 1560px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .homePageSloganContent {
    width: 100%;
    max-width: 613px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .sloganContentTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 127%;
      color: #ffffff;
      text-align: start;
      margin-bottom: 30px;
    }

    .sloganContentSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 36px;
      text-align: start;
      color: #ffffff;
      text-shadow: 2px 2px #000;

      h1 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 36px;
        text-align: start;
        color: #ffffff;
        text-shadow: 2px 2px #000;
        display: inline;
      }

      .bold {
        font-family: "PoppinsBold";
        font-weight: 900;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .homePageSlogan {
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .homePageSloganContent {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 13px;

      .sloganContentTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 127%;
        text-align: center;
      }

      .sloganContentSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        text-align: center;

        h1 {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          display: inline;
        }

        .bold {
          font-family: "PoppinsBold";
          font-weight: 900;
        }
      }
    }
  }
}
