@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

.ant-modal-wrap {
  .mobileMenuModal {
    width: 100% !important;
    padding: 0;
    max-width: 100%;
    z-index: 1000;
    top: 0;
    bottom: 0;
    margin: 0;

    .ant-modal-content {
      min-height: 100vh;
      background: rgba(217, 217, 217, 0.99);
      .ant-modal-body {
        padding: 20px 70px;

        .mobileMenuModalContainer {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .statecsLogoColorful {
            margin-bottom: 30px;
          }

          .link {
            margin-bottom: 40px;
            .headerItem {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              margin-left: 0;
            }
          }

          .ant-input-group-wrapper {
            border: 1px solid #151515;
            border-radius: 100px;
            border-radius: 50px;
            margin-bottom: 20px;
            height: 47px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .ant-input {
              background-color: transparent;
              border: none;
            }

            .ant-input-group-addon {
              background-color: transparent;
              border: none;
            }
          }

          .ant-collapse {
            margin-bottom: 10px;
            background-color: transparent;
            margin-bottom: 40px;
            padding: 0;

            .ant-collapse-borderless {
              background-color: transparent;
              border: 0;
            }

            .ant-collapse-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              .ant-collapse-header {
                background: transparent;
                padding: 0 40px 0 16px;
                max-width: 120px;
                margin-right: 0;
              }

              .ant-collapse-content {
                .ant-collapse-content-box {
                  width: 100%;
                  padding: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }

                .ant-collapse-content-box .link:nth-last-child(1) {
                  margin-bottom: 0;
                }

                .ant-collapse-content-box .link:nth-child(1) {
                  margin-top: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
}
