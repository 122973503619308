@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Ubuntu";
  src: url(../../../../assets/fonts/Ubuntu-Regular.ttf);
}

.statecsExpertise {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f8ff;

  .statecsExpertiseContainer {
    width: 100%;
    max-width: 1086px;
    padding: 60px 20px 100px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 85px;
      line-height: 128px;
      text-align: center;
      color: #151515;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 180%;
      text-align: center;
      color: #000000;
      margin-bottom: 45px;
    }

    .seeMoreButton {
      padding: 20px 85px;
      background: #2730ae;
      border: 2px solid #2730ae;
      border-radius: 100px;
      cursor: pointer;
      font-family: "Ubuntu";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      color: #ffffff;
      transition: 300ms;
    }

    .seeMoreButton:hover {
      background: #5985ef;
      border: 2px solid #ffffff;
      border-radius: 100px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .statecsExpertise {
    .statecsExpertiseContainer {
      width: 100%;
      max-width: 100%;
      padding: 50px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 54px;
        text-align: center;
        color: #151515;
        margin-bottom: 20px;
      }

      .subtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 180%;
        text-align: center;
        color: #000000;
        margin-bottom: 20px;
      }
    }
  }
}
