.blogPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f9f9f9;
  padding-bottom: 90px;

  .blogPageHeader {
    width: 100%;
    height: 542px;
    display: flex;
    background-image: url(../../assets/images/blog-page-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 1024px) {
  .blogPage {
    padding-bottom: 60px;

    .blogPageHeader {
      height: 475px;
    }
  }
}
