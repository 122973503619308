@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: "Ubuntu";
  src: url(../../../../assets/fonts/Ubuntu-Regular.ttf);
}

.ourServices {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 135px;

  .ourServicesContainer {
    width: 100%;
    max-width: 1520px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #edf1ff;

    .ourServicesInfo {
      width: 100%;
      max-width: 47%;
      padding: 176px 120px 0 126px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .ourServicesInfoTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 152.9%;
        color: #151515;
        text-align: start;
        margin-bottom: 10px;
      }

      .ourServicesInfoSubtitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 199.61%;
        color: #151515;
        text-align: start;
      }

      .learnButton {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 50px;
        width: 257px;
        height: 63px;
        background: #2730ae;
        border: 2px solid #2730ae;
        border-radius: 100px;
        margin-top: 55px;
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #ffffff;
        cursor: pointer;
        transition: 300ms;
      }

      .learnButton:hover {
        background: #5985ef;
        border: 2px solid #ffffff;
        border-radius: 100px;
      }
    }

    .ourServicesInfo .ourServicesInfoSubtitle:nth-last-child(1) {
      margin-top: 50px;
    }

    .ourServicesCards {
      width: 100%;
      background: #2730ae;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .ourServicesCardsContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 1220px) {
  .ourServices {
    .ourServicesContainer {
      .ourServicesInfo {
        padding: 176px 80px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ourServices {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .ourServicesContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #edf1ff;
      margin: 0;

      .ourServicesInfo {
        width: 100%;
        max-width: 100%;
        padding: 20px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .ourServicesInfoTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 36px;
          line-height: 152.9%;
          color: #151515;
          text-align: start;
          margin-bottom: 10px;
        }

        .ourServicesInfoSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 140%;
          color: #151515;
          text-align: start;
          margin-bottom: 20px;
        }

        .learnButton {
          margin-top: 20px;
        }
      }

      .ourServicesInfo .ourServicesInfoSubtitle:nth-last-child(1) {
        margin-top: 50px;
      }

      .ourServicesCards {
        display: none;
      }
    }
  }
}
