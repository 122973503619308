@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.servicesSectionCard {
  width: 25%;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  background: #2730ae;
  margin-right: 10px;
  transition: 1500ms;

  .icon {
    margin: 20px;
    width: auto;
    height: 60px;
  }

  .title {
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: start;
  }

  .subtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: start;
    color: #ffffff;
  }
}

.servicesSectionCard:hover {
  box-shadow: inset 0 600px 0 0 #151515;
  color: white;
}

@media screen and (max-width: 1450px) {
  .servicesSectionCard {
    height: 520px;
  }
}

@media screen and (max-width: 1220px) {
  .servicesSectionCard {
    height: 570px;
  }
}

@media screen and (max-width: 1100px) {
  .servicesSectionCard {
    height: 630px;
  }
}

@media screen and (max-width: 1024px) {
  .servicesSectionCard {
    width: 100%;
    height: 350px;
    transition: 300ms;
  }

  .servicesSectionCard:hover {
    box-shadow: none;
    background-color: #151515;
    color: white;
  }
}

@media screen and (max-width: 600px) {
  .servicesSectionCard {
    height: 450px;
  }
}
