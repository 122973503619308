@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Regular.ttf);
}

.relatedPosts {
  width: 100%;
  max-width: 1175px;
  padding: 0 5px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 90px;

  .relatedPostsTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #2a2b2d;
    margin-bottom: 28px;
  }

  .relatedPostsCarouselContainer {
    width: 100%;

    .slick-slider {
      .slick-arrow {
        background: #ffffff;
        border: 1px solid #dee4f9;
        border-radius: 50%;
        padding: 20px;
        z-index: 10;
      }

      .slick-prev::before {
        top: -20px;
        display: none;
      }
    }

    .slick-track {
      display: flex;

      .slick-slide {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .relatedPostsCarouselItem {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .relatedPosts {
    display: none;
  }
}
