.blogSinglePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f9f9f9;
  padding-bottom: 90px;

  .blogSinglePageHeader {
    width: 100%;
    height: 542px;
    display: flex;
    background-image: url(../../assets/images/blog-single-page-background.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 1024px) {
  .blogSinglePage {
    width: 100%;
    padding-bottom: 75px;

    .blogSinglePageHeader {
      height: 475px;
    }
  }
}
