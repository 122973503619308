.expertiseContent {
  width: 100%;
  max-width: 1560px;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 27px;
}
