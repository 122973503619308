@font-face {
  font-family: "Poppins";
  src: url(../../../assets/fonts/Poppins-Bold.ttf);
}

.joinUsPageContent {
  width: 100%;
  max-width: 1304px;
  padding-top: 83px 20px 100px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .joinUsPageContentTitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    text-transform: uppercase;
    color: #001f47;
    margin: 83px 0 0 0;
  }

  .joinUsPageContentSubtitle {
    max-width: 850px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #001f47;
    text-align: center;
    margin: 40px 0 53px 0;
  }
}

@media screen and (max-width: 1024px) {
  .joinUsPageContent {
    width: 100%;
    max-width: 1304px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .joinUsPageContentTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-transform: uppercase;
      color: #001f47;
      margin: 0 0 20px 0;
    }
  }
}
