@font-face {
  font-family: "Poppins";
  src: url(../../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../../assets/fonts/Poppins-Bold.ttf);
}

.whyStatecsCard {
  width: 25%;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  margin-right: 20px;
  transition: 300ms;

  .cardTitle {
    width: 100%;
    font-family: "PoppinsBold";
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 90px;
    text-align: center;
    color: #151515;
    margin-bottom: 20px;
    word-break: keep-all;
  }

  .cardSubtitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
  }
}

.whyStatecsCard:hover {
  box-shadow: -8px 10px 30px rgba(6, 10, 23, 0.5);
}

@media screen and (max-width: 1500px) {
  .whyStatecsCard {
    height: 500px;

    .cardTitle {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 48px;
      line-height: 80px;
      word-break: keep-all;
    }
  }
}

@media screen and (max-width: 1400px) {
  .whyStatecsCard {
    height: 600px;

    .cardTitle {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 48px;
      line-height: 70px;
      word-break: keep-all;
    }
  }
}

@media screen and (max-width: 1230px) {
  .whyStatecsCard {
    height: 600px;

    .cardTitle {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 40px;
      line-height: 70px;
      word-break: keep-all;
    }

    .cardSubtitle {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 1310px) {
  .whyStatecsCard {
    .cardTitle {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 38px;
      line-height: 70px;
      word-break: keep-all;
    }
  }
}

@media screen and (max-width: 1135px) {
  .whyStatecsCard {
    height: 400px;
    padding: 25px 20px;

    .cardTitle {
      font-family: "PoppinsBold";
      font-weight: 700;
      font-size: 40px;
      line-height: 70px;
      word-break: keep-all;
    }

    .cardSubtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .whyStatecsCard {
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #ffffff;
    margin-right: 0;
    margin-bottom: 10px;
    height: auto;

    .cardTitle {
      width: 100%;
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-align: center;
      color: #151515;
      margin-bottom: 20px;
      word-break: keep-all;
    }

    .cardSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #000000;
    }
  }
}

@media screen and (max-width: 500px) {
  .whyStatecsCard {
    height: 240px;
  }
}

@media screen and (max-width: 450px) {
  .whyStatecsCard {
    height: 280px;
  }
}

@media screen and (max-width: 350px) {
  .whyStatecsCard {
    height: 340px;
  }
}

@media screen and (max-width: 270px) {
  .whyStatecsCard {
    height: 400px;
  }
}
