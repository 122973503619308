@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.blogPageItemCard {
  width: 25%;
  max-width: 365px;
  height: 453px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 9px;
  background: #ffffff;
  margin-right: 18px;
  margin-bottom: 41px;
  transition: 300ms;

  .blogPageItemCardImage {
    width: 100%;
    max-width: 365px;
    height: 230px;
    border-radius: 9px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .blogPageItemCardContent {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .title {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 27px;
      color: #2a2b2d;
      margin-bottom: 10px;
      text-align: start;
      max-width: 365px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #2a2b2d;
      overflow: hidden;
      max-width: 365px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 20px;
      text-align: start;
    }

    .seeMore {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .seeMoreButton {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        cursor: pointer;

        .seeMoreButtonText {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #151515;
          margin-bottom: 5px;
        }

        .arrow {
          width: 100%;
          max-width: 96px;
        }
      }

      .seeMoreButton:hover > .seeMoreButtonText {
        font-weight: 600;
      }
    }

    .blogPageItemCardFooter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .counter {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #666a6d;
      }

      .zeel {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #666a6d;
      }
    }
  }
}

.blogPageItemCard:hover {
  box-shadow: -8px 6px 25px rgba(6, 10, 23, 0.1);
}

@media screen and (max-width: 1024px) {
  .blogPageItemCard {
    width: 100%;
    max-width: 100%;
    height: 453px;
    margin-right: 0;
    margin-bottom: 10px;

    .blogPageItemCardImage {
      width: 100%;
      max-width: 100%;
    }
  }
}
