@font-face {
  font-family: "Poppins";
  src: url(../../../../assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "PoppinsBold";
  src: url(../../../../assets/fonts/Poppins-Bold.ttf);
}

.afterTrainings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: #f6f8ff;
  margin-bottom: 70px;

  .afterTrainingsBackground {
    position: absolute;
    top: 48px;
    bottom: 0;
    left: 0;
    right: 0;
    background: #2730ae;
  }

  .afterTrainingsHeader {
    width: 100%;
    max-width: 1156px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    margin-bottom: 60px;

    .afterTrainingsHeaderImage {
      width: 420px;
      height: 420px;
      margin-right: 85px;
    }

    .afterTrainingsHeaderContent {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .afterTrainingsHeaderTitle {
        font-family: "Poppins";
        font-style: normal;
        text-align: start;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        color: #ffffff;
        margin: 80px 0 26px 0;
      }

      .afterTrainingsHeaderSubtitle {
        font-family: "Poppins";
        font-style: normal;
        text-align: start;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #ffffff;
        margin-bottom: 40px;
      }

      .applyForTraining {
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 100px;
        padding: 20px 50px;
        font-family: "Ubuntu";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        color: #151515;
        transition: 300ms;
      }

      .applyForTraining:hover {
        opacity: 0.8;
      }
    }
  }

  .afterTrainingsCardsSection {
    width: 100%;
    max-width: 1560px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;

    .afterTrainingsCardsTitle {
      font-family: "PoppinsBold";
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 54px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 40px;
    }

    .afterTrainingsCards {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .afterTrainings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    margin-bottom: 60px;

    .afterTrainingsBackground {
      top: 75px;
    }

    .afterTrainingsHeader {
      max-width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      margin-bottom: 30px;

      .afterTrainingsHeaderImage {
        width: 157px;
        height: 157px;
        margin-right: 0;
      }

      .afterTrainingsHeaderContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .afterTrainingsHeaderTitle {
          font-family: "PoppinsBold";
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          margin: 20px 0 8px 0;
        }

        .afterTrainingsHeaderSubtitle {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 30px;
        }

        .applyForTraining {
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 100px;
          padding: 20px 50px;
          font-family: "Ubuntu";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          color: #151515;
          transition: 300ms;
        }

        .applyForTraining:hover {
          opacity: 0.8;
        }
      }
    }

    .afterTrainingsCardsSection {
      max-width: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .afterTrainingsCardsTitle {
        font-family: "PoppinsBold";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .afterTrainingsCards {
        display: none;
      }
    }
  }
}
